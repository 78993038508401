"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _components = require("./components");

//
//
//
//
//
var _default = {
  name: 'Empty',
  components: {
    AppMain: _components.AppMain
  }
};
exports.default = _default;